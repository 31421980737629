import React from "react";
import { Link } from "gatsby";
import { PaymentSliderQuery } from "../../prismic/staticQueries/markets/index.js";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import MC_VISA from "../../images/siteImages/depositAndWithdraw/MC_VISA.png";
import CRYPTO from "../../images/siteImages/depositAndWithdraw/CRYPTO.png";
import LOCAL_BANK from "../../images/siteImages/depositAndWithdraw/LOCAL_BANK.png";
import GPAY from "../../images/siteImages/depositAndWithdraw/GPAY.png";
import UPI from "../../images/siteImages/depositAndWithdraw/UPI.png";
import PAYTM from "../../images/siteImages/depositAndWithdraw/PAYTM.png";

const PaymentSlider = () => {
  const language = useSelector((state) => state.language);
  const PaymentSliderData = PaymentSliderQuery(language);
  console.log("language", PaymentSliderData);
  const {
    all_payment_box,
    payment_heading,
    payment_phara
  } = PaymentSliderData;

  const all_payment_icon = [
    LOCAL_BANK, UPI, MC_VISA, CRYPTO
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="sectionbg py-5 mt-0">
      <h2 className="pt-2 text-bold-md heading text-center">{payment_heading.text}</h2>
      {/* <p className="container"><center className="mx-5 px-5">{payment_phara.text}</center></p> */}
      <p className="text-center mb-0">We offer flexible & convenient ways to deposit and withdraw funds. </p><p className="text-center">There is a variety of electronic payment systems, bank cards, and wire transfer options.</p>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all 0.5s ease-in-out"
        transitionDuration={1000}
        containerClass="carousel-container container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {all_payment_box && all_payment_box.length > 0 && all_payment_box.map((paymentmap, paymentmapInd) => (
          <div className="d-flex gap-3 align-items-center" key={"paymentmapInd" + paymentmapInd}>
            <img alt="ddd" src={all_payment_icon[paymentmapInd]} style={{ width: "150px" }} />
            <div>
              <h6 className="mb-0">{paymentmap.payment_h6.text}</h6>
              <p className="mb-0">{paymentmap.payment_com.text}</p>
              <p className="mb-0">{paymentmap.payment_with.text}</p>
            </div>
          </div>
        ))}
      </Carousel>
      </section>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(PaymentSlider);